.Background-Primary {
	background-color: $color-primary;
}

.Background-Accent {
	background-color: $color-accent;
}

.Background-WarmGrey {
	background-color: $hm-warm-grey;
}

.Background-LightGrey {
	background-color: $hm-light-grey;
}

.Background-DarkGrey {
	background-color: $hm-dark-grey;
}

.Background-Beige {
	background-color: $hm-beige;
}

.Background-MediumGrey {
	background-color: $hm-medium-grey;
}

.Background-Brown {
	background-color: $hm-brown;
}

.Background-VibrantBlue {
	background-color: $hm-vibrant-blue;
}

.Background-DeepBlue {
	background-color: $hm-deep-blue;
}

.Background-Mint {
	background-color: $hm-mint;
}

.Background-AshGrey {
	background-color: $hm-ash-grey;
}

.Background-DarkPrimary {
	background-color: $hm-dark-red;
}

.Background-DarkVibrantBlue {
	background-color: $hm-dark-vibrant-blue;
}
