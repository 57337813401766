table {
	border-collapse: collapse;
	width: 100%;
	margin: $margin-vertical-sm 0;
}

th,
td {
	@include text-sm;
	padding: 0.611rem #{ $gutter-width * 0.5 };
	border: none;
	vertical-align: top;
}


thead {
	border-bottom: 1px solid $border-color;
}

tfoot {
	border-top: 1px solid $border-color;
}

tbody tr:nth-child( odd ) td,
tbody tr:nth-child( odd ) th {
	background: $hm-light-grey;
}

th {
	color: $color-text-heading;
	text-align: left;
	@include font-heading;
}

table caption {
	@include text-sm;
	margin-bottom: $margin-vertical-sm * 0.5;
}
