@mixin clearfix {
	&:before,
	&:after {
		display: table;
		content: " ";
	}

	&:after {
		clear: both;
	}
}

.util-clearfix {
	@include clearfix;
}
