.btn {
	@include font-body;
	@include text-md;
	border: 1px solid $color-link;
	border-radius: 4px;
	padding: calc( #{ $margin-vertical-sm * 0.25 } + 1px ) $gutter-width;
	background: $color-link;
	color: $color-white;
	margin-bottom: $margin-vertical-sm * 0.5;
	margin-right: $gutter-width * 0.5;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	vertical-align: middle;
	width: auto;
	box-shadow: none;
	text-shadow: none;
	height: auto;
	transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;

	&:link,
	&:visited {
		color: $color-white;
		text-decoration: none;
	}

	&:hover,
	&:focus {
		color: $color-link;
		text-decoration: none;
		outline: none;
		background: $color-white;
		color: $color-link;
		transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;
	}

	&[disabled] {
		cursor: default;
		opacity: 0.3;

		&:hover,
		&:focus {
			background: $color-link;
			color: $color-white;
		}
	}

	&--block {
		width: 100%;
		margin-bottom: $margin-vertical-sm;
		margin-right: 0;
	}
}

.btn--secondary {
	color: $color-link;
	border-color: $color-link;
	background: $color-white;

	&:link,
	&:visited {
		color: $color-link;
	}

	&:hover,
	&:focus {
		color: $color-white;
		background: $color-link;
		border: 1px solid $color-link;
	}

	&[disabled] {
		&:hover,
		&:focus {
			background: $color-link;
			border-color: $color-link;
			color: $color-white;
		}
	}

}

.btn--tertiary {
	color: $color-text-default;
	border-color: $color-text-default;
	background: none;

	&:link,
	&:visited {
		color: $color-text-default;
	}

	&:hover,
	&:focus {
		color: $color-white;
		background: $color-text-default;
		border: 1px solid $color-text-default;
	}

	&[disabled] {
		&:hover,
		&:focus {
			background: none;
			border-color: $color-text-default;
			color: $color-text-default;
		}
	}
}

.btn--inverted {
	color: $color-white;
	border-color: $color-white;
	background: none;

	&:link,
	&:visited {
		color: $color-white;
	}

	&:hover,
	&:focus {
		color: $color-link;
		background: $color-white;
		border: 1px solid $color-link;
	}
}

.btn--small {
	@include text-std;
	border-width: 1px;
	padding: 0 #{ $gutter-width * 0.5 };
}

.btn--loading {

	&:after {
		display: none;
	}

	.loading--active {
		@extend .loading--white;
		margin-left: $gutter-width * 0.5;
		margin-bottom: -1px;
	}

	&:hover .loading--active {
		@extend .loading--blue;
	}

}
