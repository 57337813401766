.footer {

	@include font-heading;
	@include text-sm;
	color: $color-footer-text;
	border-top: 1px solid $border-color;
	margin: $margin-vertical-lg 0;

	.hm-logo--tiny {
		margin-left: 0;
		opacity: 0.6;
		position: relative;
		top: -1px;
	}

	a:link,
	a:visited {
		color: inherit;
	}

}
